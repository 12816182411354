import { browser } from "$app/environment"
import { BehaviorSubject, fromEvent, Observable, Subject } from "rxjs"

export const keyDown$ = browser
    ? fromEvent<KeyboardEvent>(document, "keydown")
    : new Observable<KeyboardEvent>()
export const keyUp$ = browser
    ? fromEvent<KeyboardEvent>(document, "keyup")
    : new Observable<KeyboardEvent>()

export const shiftDown$ = new BehaviorSubject(false)
export const escapeDownEvent$ = new Subject<void>()
export const metaK$ = new Subject<void>()
export const ctrlK$ = new Subject<void>()

keyDown$.subscribe((e) => {
    if (e.key === "Shift") shiftDown$.next(true)
    if (e.key === "Escape") escapeDownEvent$.next()
    if (e.key === "k" && e.metaKey) {
        metaK$.next()
        e.preventDefault()
    }
    if (e.key === "k" && e.ctrlKey) {
        ctrlK$.next()
        e.preventDefault()
    }
})

keyUp$.subscribe((e) => {
    if (e.key === "Shift") shiftDown$.next(false)
})
